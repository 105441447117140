import Vue from 'vue';
import Vuex from 'vuex';
import moment from 'moment';
Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        contentList: [],
        errorNodes: [],
        zoomLevel: 1
    },
    mutations: {
        SET_CONTENT_LIST(state, contentList) {
            state.contentList = contentList;
        },
        SET_ERROR_NODES(state, errorNodes) {
            state.errorNodes = errorNodes;
        },
        SET_ZOOM_LEVEL(state, zoomLevel) {
            state.zoomLevel = zoomLevel;
        }
    },
    getters: {
        contentList: state => state.contentList,
        errorNodes: state => state.errorNodes,
        zoomLevel: state => state.zoomLevel
    },
    actions: {
        updateContentList({ state }, content) {
            if (state.contentList.length) {
                let index = state.contentList.findIndex(x => x.id === content.id);
                if (index > -1) {
                    state.contentList.splice(index, 1, content);
                } else {
                    state.contentList.push(content);
                }
            }
        }
    }
});
