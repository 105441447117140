/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

import cookie from './mixins/cookie';

require('./bootstrap');

import 'vuetify/dist/vuetify.min.css';
import '../sass/admin.scss';
import '@mdi/font/css/materialdesignicons.css';
import './helpers/filters.js';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

window.Vue = require('vue');
import Vuex from 'vuex';

import Vuetify from 'vuetify';
import VueYouTubeEmbed from 'vue-youtube-embed';

Vue.use(Vuetify);
Vue.use(Vuex);
Vue.use(VueYouTubeEmbed);

import store from './vuex/store';

/**
 * Add a 401 response interceptor
 * If we receive 401 in moderator Application redirect to login page
 */
window.axios.interceptors.response.use(
    response => {
        return response;
    },
    error => {
        if (401 === error.response.status && error.response.statusText === 'Unauthorized') {
            window.location = '/admin/login';
        } else {
            return Promise.reject(error);
        }
    }
);

const files = require.context('./', true, /\.vue$/i);
files.keys().map(key =>
    Vue.component(
        key
            .split('/')
            .pop()
            .split('.')[0],
        files(key).default
    )
);

const app = new Vue({
    el: '#app',
    store,
    vuetify: new Vuetify({
        customVariables: ['../sass/_variables.scss'],
        theme: {
            dark: false,
            themes: {
                light: {
                    primary: '#1097E2',
                    secondary: '#38474F',
                    accent: '#ffc107',
                    error: '#f44336',
                    warning: '#ff9800',
                    info: '#03a9f4',
                    success: '#4caf50'
                }
            }
        }
    }),
    mixins: [cookie],
    data: {
        drawer: false, // controls the navigation-drawer
        fluid: true,
        mini: true
    },
    mounted() {
        this.mini = this.getCookie('mini_drawer') ?? false;
    },
    methods: {
        logout() {
            document.getElementById('logout-form').submit();
        }
    },
    watch: {
        mini: {
            handler(newValue) {
                this.setCookie('mini_drawer', newValue, 232000);
            }
        }
    }
});

if (APP_ENV == 'production') {
    Sentry.init({
        debug: true,
        dsn: SENTRY_LARAVEL_DSN,
        integrations: [
            new Integrations.Vue({ app, attachProps: true, logErrors: true, tracing: false })
        ]
    });

    if (window.authUser) {
        Sentry.configureScope(function(scope) {
            scope.setUser(window.authUser);
        });
    }
}
